document.body.addEventListener('benefit-bar-carousel:init', function () {
  let elementList = document.querySelector('.content-central-benefit-bar');
  let triggerElementList = document.querySelectorAll('.trigger-description-notification');

  if (elementList) {
    elementList.addEventListener('click', function () {
      triggerElementList.forEach(function (item) {
        item.classList.toggle('opened');
      });
    });
  }
});
