const headerSearchComponent = document.getElementById('header-serach-component');

let scrollDistance = 0;
$('.js-main-search').on('click', () => {
  let headerHeight = $('header')[0].clientHeight.toString() + 'px';
  document.documentElement.style.setProperty('--header-height', headerHeight);
  headerSearchComponent.style.display = 'block';
  $('.wrapper-nav').addClass('menu-blocked');
  document.querySelectorAll('.main--layout-page .main-overlay-page')[0].style.display = 'block';
  !window.isMobile() ? $('input.search-field').trigger('focus') : '';
  $('.header-search').addClass('is-open');
  $('.header-search').removeClass('is-closed');
  $('body').trigger('search:open');
  if (window.isMobile()) {
    // Get the scroll distance at the time the modal was opened
    scrollDistance = window.pageYOffset;
    // $('body')[0].style.overflow = 'hidden';
    // $('body')[0].style['overflow-y'] = 'hidden';
    // $('body')[0].style['position'] = 'fixed';
    // $('body')[0].style['width'] = '100%';
    // $('body')[0].style['top'] = '-' + scrollDistance;

    $('body')[0].setAttribute("style", "overflow: hidden;position:fixed;top: -"+ scrollDistance +"px;")
    const minicart = document.querySelector('.layer-minicart');
    minicart.dataset.lastoffset = scrollDistance;
    $('.header-search.top')[0].style['top'] = scrollDistance+'px';
  }
  if ($('.header-search .einstein-carousel').length == 0) {
    $('.header-search').addClass('no-recommender-available');
  }
  var height = $('.suggestions-wrapper')[0].clientHeight;
  var total = height + 70;
  document.documentElement.style.setProperty('--min-rec-height', total.toString() + 'px');
  window.dispatchEvent(new Event('resize'));
});
$('#search-bar-close').on('click', () => {
  $('.wrapper-nav').removeClass('menu-blocked');
  $('.header-search').removeClass('is-open');
  $('.header-search').addClass('is-closed');

  if (window.isMobile()) {
    $('body')[0].style.overflow = 'visible';
    $('body')[0].style['overflow-y'] = 'visible';
    $('body')[0].style['position'] = 'unset';
    $('body')[0].style['top'] = '0';
    const minicart = document.querySelector('.layer-minicart').dataset.lastoffset;

    // // Set the window's scroll position back to what it was before the modal was opened
    window.scrollTo(0, minicart);
  }
  setTimeout(function() {
    document.querySelectorAll('.main--layout-page .main-overlay-page')[0].style.display = 'none';
    $('.header-search').removeClass('is-closed');
  }, 500);

  // setTimeout(function() {
  //   headerSearchComponent.style.display = 'none';
  // }, 1000);
  $('.js-main-search').trigger('focus');
});
