function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

module.exports = {
  init: function () {
    const launchClass = '.cookie-popup-launch';
    const elements = document.querySelectorAll(launchClass);

    if (elements) {
      [...elements].forEach((item) => {
        item.addEventListener('click', (e) => {
          if (utag && utag.gdpr) {
            utag.gdpr.showConsentPreferences();
          }
        });
      });
    }
  },
  onAccept: function() {  
    $('.cookies-accept-button, #submitCookieButton, #submitCookieButtonAll, .cookies-modal-popup-content .modal-close').on('click', function () {
      let url = $('.newsletter-layer-url')[0].dataset.newsletterLayer;
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          console.log('triggerNewsletter');
          $('.newsletter-modal-placeholder')[0].innerHTML = data;
          setTimeout(function() {
              $('#newsletter-modal')[0].ariaHidden = 'false';
              $('#newsletter-modal').addClass('is-open');
          }, 1000);

        },
        error: function () {
          console.log('errorNewsletter');
        }
      });
    });
  },
  onClose: function() {
    $('.newsletter-modal-placeholder').on('click', function (e) {
      if (e.target.className == 'modal-close') {
        $('#newsletter-modal')[0].ariaHidden = 'true';
        $('#newsletter-modal').removeClass('is-open');
      }
    });
  },
  onCloseDynamic: function() {
    $('#dynamic-modal .modal-close').on('click', function (e) {
      if (e.target.className == 'modal-close') {
        var cookie= $('#dynamic-modal')[0].dataset.popupId;
        setCookie(cookie, true, 30);
        $('#dynamic-modal')[0].ariaHidden = 'true';
        $('#dynamic-modal').removeClass('is-open');
      }
    });
  },
};
